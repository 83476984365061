import React from 'react'

export default function scroll() {
    return (
        <div className="text-white fill--transition text-will-change is-dark   m-hide flex fill-transition mla align-bottom         ">
            <p className='p m0 pt20  '>SCROLL</p>
            <div className="scroll--arrow fill--transition ">
                <svg className='' width="11" height="8" viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.646447 4.3534C0.451184 4.15814 0.451184 3.84156 0.646447 3.6463L3.82843 0.464318C4.02369 0.269056 4.34027 0.269056 4.53553 0.464318C4.7308 0.65958 4.7308 0.976162 4.53553 1.17142L2.20711 3.49985H11V4.49985H2.20711L4.53553 6.82828C4.7308 7.02354 4.7308 7.34012 4.53553 7.53539C4.34027 7.73065 4.02369 7.73065 3.82843 7.53539L0.646447 4.3534Z" />
                </svg>
            </div>
        </div>
    )
}
