import React from "react";
import ContentContainer from "./contentContainer";
import BlurredImage from "./blurredImage";
import { GatsbyImage } from "gatsby-plugin-image";
import Video from "./Video";

const BlurredImageInnerContainer = ({ children, className, style,ratio }) => (
    <div className={"max-450 inner-image m-max-50p  " + className} style={style}>
        <div className={ratio}>
            {children}
        </div>
    </div >
)

export const Image = ({ image,className = "" }) => (
    <GatsbyImage
        className={`bg-image ${className}`}
        image={image?.gatsbyImageData}
        alt=""
    />
)


const ImageText = ({ image, children, className, video = false, wrapperClassName,outerVideo,autoplay, imageRatio = 'ratio-2-3' }) => {
    const x = image?.focalPoint?.x
    return (
        <ContentContainer className={"feature--banner  m-p0 " + wrapperClassName}>
            <div className={"flex m-wrap " + className}>
                <div className="image--banner w-50 pos-rel m-100 overflow-hidden">
                    <BlurredImage x={x}  imageRatio={imageRatio} image={image} video={outerVideo}  >
                        {!video ?
                            <BlurredImageInnerContainer  ratio={imageRatio}>
                                <Image   image={image} />
                            </BlurredImageInnerContainer >
                            :
                            <BlurredImageInnerContainer ratio={imageRatio}>
                                <Video x={x} url={image?.url} autoplay={autoplay} className="bg-image z-2 " video={video} />
                            </BlurredImageInnerContainer >
                        }
                    </BlurredImage>
                </div>
                <div className="w-50 flex align-center m-100">
                    <div className="inner p40 ma m-p0 m-m0 ">{children}</div>
                </div>
            </div>
        </ContentContainer>
    );
};

export default ImageText;


// can export image and insert it or can do alternative 
