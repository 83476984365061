import React, { useEffect } from "react"
import Logo from "../components/partials/logoLarge"
import { GatsbyImage } from "gatsby-plugin-image"
import { gsap } from "gsap";

const Introduction = ({ images, noPlay }) => {

  useEffect(() => {
    if (!noPlay) {
      gsap.to('body', { position: 'fixed' })
      gsap.to('.introduction--animation .image-wrapper', { opacity: 0.7, duration: 1.5, delay: .5, ease: "power3.inOut" });
      gsap.to('.introduction--animation .bg-image', { scale: 1, duration: 2, delay: .5, ease: "power2.inOut" });
      var tl = gsap.timeline({
        delay: 2.5, onComplete: function () {
          gsap.to('.introduction--animation .introduction--logo', { opacity: 0, duration: 1.5, ease: "power3.inOut" });
          gsap.to('.introduction--animation .image-wrapper', { opacity: 1, duration: 1.5, ease: "power3.inOut" });
          gsap.to('.introduction--animation', { opacity: 0, display: 'none', duration: 1, ease: "power3.inOut" });

          setTimeout(function () {
            document.querySelector('.masthead').classList.add('is-active');
            gsap.fromTo('.scrolling--banner-text .sticky-title .h1', { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1, delay: 0.25, ease: "power1.inOut" });
            gsap.fromTo('.scrolling--banner-text .sticky-title .content', { opacity: 0, y: 20 }, { opacity: 1, y: 0, delay: .5, duration: 1, ease: "power1.inOut" });

          }, 250);
        }
      });
      console.log(document.querySelectorAll(`.introduction--image:not([data-image="${(images.length  - 1)}"])`))
      tl.staggerTo(`.introduction--image:not(.image-${(images.length  - 1)})`, 0.3, { zIndex: 2 }, 0.3);  

      gsap.to('body', { position: 'unset' }, ">")
    }
  }, [noPlay,images.length]);

  const Images = images.map((image, index) => {
    return (
      <GatsbyImage  key={index} className={`bg-image introduction--image image-${index}`} image={image?.gatsbyImageData} alt='Intro Image' />
    )
  });

  if (!noPlay) {
    return (
      <div className="introduction--animation pos-rel h-100vh flex align-center justify-center">
        <div className="image-wrapper">
          <div className="image--inner">
            <div className="bg-image">
              {Images}
            </div>
          </div>
        </div>
        <div className="introduction--logo">
          <Logo />
        </div>
      </div>
    )
  } else {
    return null
  }

}

export default Introduction
