import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Video from "./Video";

const BlurredImage = ({ children, image,video, imageRatio  }) => {
   
    
    const Background =  () =>{
        if(video){
            return(<Video url={image.url} controls={false} autoPlay={true} video={video}  muted={true}   />) 
        }else{
            return (<GatsbyImage className="bg-image" image={image?.gatsbyImageData} alt=""/>)
        }
    
    }
    return (
        <div className={`${imageRatio} overflow-hidden`} >
            {children}
            <Background/>  
        </div>
    );
};
export default BlurredImage;
