import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const NewsCarousel = ({ articles }) => {
  const Articles = articles.map((article, index) => {
    return (
      <div key={index} className="pl40 inline pr40 carousel--article">
        <div className="inner flex">
          <div className="w-33">
            <GatsbyImage objectFit="contain" className="m-mh90" alt="CTA" image={article.node.featureImage?.gatsbyImageData} />
          </div>
          <div className="w-66 pl40">
            <p className="m0 mb20 mono">{article.node.location}</p>
            <div className="op-50">
              {article.node.info}
            </div>
          </div>
        </div>
      </div>
    )
  });
  return (
    <div className="news--carsousel p40 pt0 m-pt0">
      <div className="max-1400 ma bb1 bt1">
        <div className="spacer "></div>
        <div className="article--marquee">
          <div className="marquee-inner">
            {Articles}
          </div>
          <div className="marquee-inner">
            {Articles}
          </div>
        </div>
        <div className="spacer "></div>
      </div>

    </div>
  )
}

export default NewsCarousel
