import { gsap } from "gsap";
import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ConditionallyRender from "./conditionallyRender";
import Scroll from "./scroll";

const ScrollingBanner = ({ title, image, content, position, video, mobile, videoClass, children }) => {
  useEffect(() => {
    gsap.fromTo('.banner--title', { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: .75, delay: 0.5, ease: "power2.inOut" });
  }, []);
  return (
    <>
      <div className={"scrolling--banner h-100vh background--container " + (mobile ? 'm-h65':'')}>
        <ConditionallyRender when={video}>
          <video className={"bg-image cover " + videoClass} autoPlay={true} muted={true} playsInline={true} controls={false} loop={true} >
            <source src={video} type="video/mp4" />
            <track kind="captions" srcLang="en" src='' />
          </video>
        </ConditionallyRender>
        <ConditionallyRender when={!video}>
          <GatsbyImage className='bg-image fade-out' image={image?.gatsbyImageData} alt={title} />
        </ConditionallyRender>
      </div>
      <div className={"h-100vh scrolling--banner-text flex  align-bottom p40  ma m-pb80 "+(mobile ? 'm-h65 m-p0':'')}>
        <div className="flex w-100  ">
          <div className={"  banner--title text-white fill--transition text-will-change is-dark fill-transition " + (mobile ? 'm-hide' : '')}>
            <p className="m0 h1 f-80" dangerouslySetInnerHTML={{ __html: title }}></p>
            <div className={"content max-300 mt40 m-mt8 m-mw180  " + (content ? '' : 'hide')}>
              {content}
            </div>
          </div>
          <Scroll/>
        </div>

      </div>
      <div className={"p40 "+(mobile ? 'm-bg-white':'')}>
      <div className={"max-1400 ma bt1 m-b0 background-will-change fill--transitiom is-dark "+(mobile ? 'm-bg-white':'')}>
        {( mobile ? 
          <div className='m-show '>
            <p className="m0 h1 m-pb20 bb1 " dangerouslySetInnerHTML={{ __html: title }}></p>
          </div>
          : ''
        )}
      </div>
      </div>
      <div className={"max-1400 ma p40 "+(mobile ? 'm-bg-white':'')}>
        <ConditionallyRender when={children}>
          <div className="">
            {children}
          </div>
        </ConditionallyRender>
      </div>

    </>
  )
}

export default ScrollingBanner
