import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from "./spacer";

const CtaBanner = ({ image, link, title, button, clicked }) => {

  useEffect(() => {
    document.querySelector('.cta--banner').addEventListener("click", clicked);
  }, [clicked])

  return (

    <div className="p40 m-p0" >
      <Spacer className='m-show' />
      <div className="max-1400 ma cta--banner">
        <div className="ratio-1-2 m-ratio-2-1 image-wrapper">
          <div className="inner z-2">
            <div className="p40 max-1200 ma">
              <p className="m0 h1 mb40 white-no-modes">{title}</p>
              <p className="m0 button white-no-modes">{button}</p>
            </div>
          </div>
          <GatsbyImage className='bg-image filter-15' alt="CTA" image={image?.gatsbyImageData} />
        </div>
      </div>
      <div className="spacer"></div>
    </div>

  )
}

export default CtaBanner
