import * as React from "react"

const LogoLarge = () => {
  return (
  <svg className="logo--large" width="408" height="93" viewBox="0 0 408 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M72.4519 64.8414C72.4519 79.5656 60.1571 93 36.2291 93C10.9967 93 0 76.7242 0 59.9313H12.9376C13.1935 68.9727 17.9804 81.6344 36.2228 81.6344C54.0784 81.6344 58.9901 72.4621 58.9901 65.6141C58.9901 57.607 53.9473 53.2141 36.0918 51.0145C14.8723 48.4285 3.10178 41.0695 3.10178 25.5664C3.10802 11.3656 15.2655 0 36.3539 0C58.7342 0 68.5638 14.207 68.957 28.9313H56.4063C56.4063 20.9242 50.9704 11.3656 36.098 11.3656C20.8325 11.3656 16.4326 18.8555 16.4326 24.8C16.4326 30.352 18.8915 35.9102 37.3899 38.3652C57.4423 41.0758 72.4519 45.7242 72.4519 64.8414Z" />
    <path d="M100.381 91.9654V1.03418H113.836V91.9716H100.381V91.9654Z"  />
    <path d="M159.37 12.3998V40.8139H197.665V52.1795H159.37V80.5936H205.946V91.9592H145.914V1.03418H205.946V12.3998H159.37Z" />
    <path d="M232.453 1.03418H266.348C291.705 1.03418 300.761 12.6615 300.761 28.9373C300.761 40.951 291.063 50.2478 277.22 52.0549C276.44 52.1857 275.922 52.4412 275.922 53.2139C275.922 54.1174 276.571 54.3791 277.345 54.3791C290.027 55.0271 293.521 59.931 295.325 64.972L304.905 91.9716H288.604L283.043 70.5302C280.584 60.8408 273.6 57.8748 260.145 57.8748H245.915V91.9716H232.459V1.03418H232.453ZM265.181 46.5029C277.732 46.5029 287.305 38.8822 287.305 29.1928C287.305 18.4752 280.84 12.3998 268.289 12.3998H245.909V46.4967H265.181V46.5029Z" />
    <path d="M385.62 70.2685H343.443L336.066 91.9654H321.057L354.178 1.03418H374.879L408 91.9716H392.991L385.62 70.2685ZM381.869 58.9029L366.859 14.4685C366.341 12.7924 365.823 11.2408 365.568 9.04121C365.436 8.26855 365.437 7.48966 364.532 7.48966C363.627 7.48966 363.627 8.26232 363.496 9.04121C363.233 11.2346 362.722 12.7861 362.204 14.4685L347.194 58.9029H381.869Z" />
  </svg>
  )  
}

export default LogoLarge
