import React from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import generateSlug from "../lib/generateSlug"

const Introduction = ({ projects , hide,left,right }) => {
  const items = ((left && right) ? [{node:left},{node:right}] : projects.slice(0,2))
  console.log(items)
  const Projects = items.map((project, index) => {
    return (
      <Link to={`/projects/${generateSlug(project.node.title)}`} key={index} className={"feature--project link image-will--zoom m-100 " + (index === 0 ? 'w-66' : 'w-33 pl20 m-p0 m-mt100')}>
        <div className="ratio-1-2 m-ratio-2-1 bg-grey overflow-hidden">
          <GatsbyImage className='bg-image' image={project.node.featureImage?.gatsbyImageData} alt={project.node.title} />
        </div>
        <div className="uppercase mt20 flex m-mt10 button small ">
          <p className="title m0">{project.node.title}</p>
          <p className="op-50 m0 mla mra">{project.node.location}</p>
        </div>
      </Link>
    )
  });
  return (
    <div className="feature--projects p40 ">
      <div className="max-1400 ma">
        <div className="projects align-bottom m-wrap flex">
          {Projects}
        </div>
        <div className={hide}>
          <div className="spacer"></div>
          <Link className="link button" to="/developments">See all projects</Link>
          <div className="spacer"></div>
        </div>
      </div>
    </div>
  )
}

export default Introduction
