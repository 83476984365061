import React from 'react'

const ContentContainer = ({children,className,innerClass}) =>{
    return(
        <div className={className + ' p40'}>
            <div className={innerClass + ' max-1400 ma '}>
                {children}
            </div>
        </div>
    )
}
export default ContentContainer