import React from "react"
import Link from "../components/link"
import ImageText from "./imageText"
import Spacer from "./spacer"


const Introduction = ({ title, image, author, button, link, className,videoUrl }) => {
  return (
    <>
    <Spacer/>
    <ImageText wrapperClassName='p0' image={image} key={title} outerVideo={videoUrl}>
             <div className="inner p40 ma m-m0">
          <p className="m0 h2 max-450">{title}</p>
          <div className="spacer"></div>
          <Link className="link button" to={link}>{button}</Link>
        </div>
      </ImageText> 
      <Spacer/>
      </>
  )
}

export default Introduction
