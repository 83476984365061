import React, { useEffect, useRef, useState } from "react";

const Video = ({x = 0.5, video, playingClass, pausedClass,url, className, controls = true,autoplay = true, ...props }) => {

    const [playing, setPlaying] = useState(false)
    const media = useRef()

    useEffect(() =>{
        if(autoplay){
            setPlaying(true)
        }else{
            setPlaying(false)
        }
    },[autoplay])

    function toggleVideo() {
        const video = media.current;
        if (controls) {
            

            if (video.paused) {
                setPlaying(true)
                video.play();
            } else {
                setPlaying(false)
                video.pause();
            }
        }else{
            video.play();
        }
    }
    return (

        <button onClick={toggleVideo} className={'bg-image video ' + className + ' ' + (playing ? playingClass : pausedClass)}>
            <video onPause={() => setPlaying(false)} style={{objectPosition:`${x*100}%` }} poster={url} muted={autoplay} autoPlay={autoplay} playsInline={true} {...props} loop={false} className="bg-image cover   " ref={media}>
                <source src={video} type="video/mp4"></source>
                <track kind="captions" src='' />
                Your browser does not support HTML5 video.
            </video>
            <div className={'bg-image  video ' + (playing ? 'playing' : 'paused')} />
        </button>
    )
}


export default Video